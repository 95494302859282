import jquery from "jquery"
window.$ = window.jQuery = jquery

window.onscroll = function () {
  myFunction()
}

let header = document.querySelector('.header')
let sticky = header.offsetTop

function myFunction() {
  $('.nav-small').removeClass('d-flex d-lg-none')
  if (window.pageYOffset >= sticky) {
    header.classList.add('sticky')
  } else {
    header.classList.remove('sticky')
  }
}